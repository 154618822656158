import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import TemplateMainContent from 'Stores/awd-performance-parts/Templates/MainContent.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import { FacetBar } from 'Components'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/awd-performance-parts/Templates/fitmentSearch/singleVehicleGarage.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/awd-performance-parts/Templates/fitmentSearch/fitmentTable.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchHeader,
SearchResult,
VehicleWidget,
FacetBar,
Garage,
SingleVehicleGarage,
VerifyFitment,
FitmentTable,
FacetTiles,
SearchBox,
SearchBoxDialogButton,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};