
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'garage-container navPages-action' }, _createElement('div', {
        'className': 'garage-wrapper',
        'onClick': window.Convermax.handleGarageClick
    }, _createElement('div', { 'className': 'cm_changeButton' }, _createElement('i', {
        'className': 'fa fa-car',
        'aria-hidden': 'true'
    }), this.template === 'active' ? _createElement('div', {
        'className': 'active-container cmTemplate_active',
        'key': '213'
    }, '\n        Shop by Vehicle\n        ', _createElement('i', {
        'className': 'fa fa-angle-double-right',
        'aria-hidden': 'true'
    })) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'locked-container cmTemplate_locked',
        'key': '415'
    }, _createElement('span', { 'className': 'model' }, this.vehicleString)) : null)));
}
        export const componentNames = []