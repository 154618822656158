export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',

  PRODUCT_REPEATER_CLASS: 'productGrid',

  SEARCH_BOX_CLASS: 'form-fieldset',
  SEARCH_BOX_FORM_CLASS: 'form-field',
  SEARCH_BOX_INPUT_CLASS: 'form-input',

  FACET_INPUT_CLASS: 'form-input',
  FACET_TITLE_ICON: `<i class="icon{!this.isCollapsed ? ' is-open' : ''}"><svg><use xlinkHref="#icon-add"></use></svg></i>`,

  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  SELECT_YOUR_VEHICLE: 'Shop by Vehicle',
  SELECT_YOUR_VEHICLE_BLOCK: 'Shop by <span>Vehicle</span>',
  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',

  GARAGE_ICON_CONTAINER_CLASS: 'navUser-action',
  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',

  FITMENT_TABLE_VEHICLE_SPECIFIC: 'This Part Fits:',
};
