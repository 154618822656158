const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';

globalThis.Convermax.handleGarageClick = () => {
  document.querySelector('#cm_HeaderVehicleWidget').classList.toggle('closed');
};

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );
  const compareBtn = window.document.body.querySelector('[data-compare-nav]');
  if (!compareBtn) {
    return;
  }

  compareBtn.querySelector('span.countPill').innerHTML = productsIdsToCompare.length;
  compareBtn.classList.toggle('show', productsIdsToCompare.length >= 1);
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

export default {
  platform: 'bigcommerce',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    onVehicleSelected: globalThis.Convermax.handleGarageClick,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: ['page-type-category', 'page-type-brand']
        .map((className) => `body.${className} #product-listing-container`)
        .join(),
      template: 'MainContent',
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: {
        replace: ['page-type-category', 'page-type-brand']
          .map((className) => `body.${className} .page-sidebar .sidebarBlock`)
          .join(),
        class: 'sidebarBlock',
      },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        lastChildOf: 'header.header',
        class: 'cm_vehicle-widget__home_container closed',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 780,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.navUser-section .navUser-item.navUser-item--cart',
        class: 'cm_garage-container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        insertBefore: '#navPages-main .navPages-item-page',
        class: 'cm_single-vehicle-garage navPages-item navPages-item-page',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertAfter: 'body.page-type-product .productView-options',
        class: 'awd__productView-belowProductOptions',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: { firstChildOf: '#cm_fitment-table' },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: {
        replace: 'body.page-type-brands .brandGrid',
        class: 'cm_vehicle-categories__brand',
      },
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        replace: 'body.page-type-category .page',
      },
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
      visibleIf: { urls: [categorySelectionPageUrl] },
    },
    {
      name: 'SearchBox',
      location: {
        replace: '.dropdown.dropdown--quickSearch form',
        class: 'form',
      },
      template: 'SearchBox',
      onSubmit: () =>
        window.document
          .querySelector('.dropdown--quickSearch')
          .classList.remove('is-open', 'f-open-dropdown'),
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        firstChildOf: 'header .navUser-section',
        class: 'navUser-item navUser-item--search cm_search-box_container cm_desktop-hide',
        wrapper: 'li',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
