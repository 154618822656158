//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-324:_4204,_7908,_7460,_2712,_5588,_9508,_4236,_7404;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-324')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-324', "_4204,_7908,_7460,_2712,_5588,_9508,_4236,_7404");
        }
      }catch (ex) {
        console.error(ex);
      }